// Insert vehicle image into vehicle bar
function insertVehicleImage() {
  var vehicleData = $('.vehicleData').data();
  var getVehicle = 'https://iconfigurators.app/api/?function=getVehiclePreview';
  getVehicle += '&year=' + vehicleData.year;
  getVehicle += '&make=' + vehicleData.make;
  getVehicle += '&model=' + vehicleData.model;
  getVehicle += '&key=' + vehicleData.key;
  $.getJSON(getVehicle, function (data) {
    if (data.result === 'failed') {
      console.error(data.message);
      return;
    }
    if (data.vehicles[0] !== undefined) {
      var returnedImgUrl = data.vehicles[0].previewImage;
      var vehicleInfo = vehicleData.year + ' ' + vehicleData.make + ' ' + vehicleData.model;
      var returnedImg = '<img ';
      returnedImg += 'src="' + returnedImgUrl + '"';
      returnedImg +=
        'alt="' + vehicleData.year + ' ' + vehicleData.make + ' ' + vehicleData.model + '"';
      returnedImg += '/>';
      //$('.vehicleData').append(returnedImg);
      $('.vehicleData img').attr('src',returnedImgUrl).attr('alt',vehicleInfo);
    }
  });
}

if ($('.setVehicleImg').length > 0) {
  insertVehicleImage();
}


// Insert vehicle image into vehicle bar
/*function insertVehicleImage() {
  var vehicleData = $('.vehicleData').data();
  var getVehicle = 'https://iconfigurators.app/api/?function=getVehiclePreview';
      getVehicle += '&year=' + vehicleData.year;
      getVehicle += '&make=' + vehicleData.make;
      getVehicle += '&model=' + vehicleData.model;
      getVehicle += '&submodel=' + vehicleData.submodel;
      getVehicle += '&option=' + vehicleData.option;
      getVehicle += '&key=' + vehicleData.key;
  $.getJSON(getVehicle, function(data) {
    console.log('vehicle bar data: ' + data);
    if (data.vehicles[0] != null) {
      var returnedImgUrl = data.vehicles[0].previewImage;
      var returnedImg = '<img ';
          returnedImg += 'src="' + returnedImgUrl + '"';
          returnedImg += 'alt="' + vehicleData.year + ' ' + vehicleData.make + ' ' + vehicleData.model + '"';
          returnedImg += '/>';
      $('.vehicleData').append(returnedImg);
    } else {
      console.log('it is nulllllll');
    }
  });
}

if ($('.setVehicleImg').length > 0) {
  insertVehicleImage();
}*/
