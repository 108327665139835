$('.fitment-open').magnificPopup({
  items: {
    src: '#fitment-popup',
    type: 'inline'
  },
  callbacks: {
    close: function () {
      location.reload();
    }
  }
});

$('.fitment-tires-open').magnificPopup({
  items: {
    src: '#fitment-tires-popup',
    type: 'inline'
  },
  callbacks: {
    close: function () {
      location.reload();
    }
  }
});

$('.checkFitment-open').magnificPopup({
  items: {
    src: '#checkFitment-popup',
    type: 'inline'
  }
});

$('.js-search-btn').magnificPopup({
  items: {
    src: '<div class="white text-center processing">' +
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
      '</div>',
    type: 'inline',
    modal: true
  },
  callbacks: {
    open: function () {
      $('#search-form').submit();
    }
  }
});

$('.js-tireFitment').magnificPopup({
  items: {
    src: '#app-guide-tires-popup',
    type: 'inline'
  }
});

$('.js-accessoryFitment').magnificPopup({
  items: {
    src: '#app-guide-accessory-popup',
    type: 'inline'
  }
});

$('.js-fitmentCheck').magnificPopup({
  items: {
    src: '#app-guide-fitment-check-popup',
    type: 'inline'
  }
});

// Gallery Detail Quote Popup
$('.open-galleryDetail-quote-popup').magnificPopup({
  items: {
    src: '#galleryDetail-quote-popup',
    type: 'inline'
  }
});

// RFQ Popup
$('.open-quote-popup').magnificPopup({
  items: {
    src: '#quote-popup',
    type: 'inline'
  }
});

// WillItFit Popup
$('.open-will-it-fit-popup').magnificPopup({
  items: {
    src: '#will-it-fit-popup',
    type: 'inline'
  }
});

// Gallery View Popup
$('.wheel-gallery-photo').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

// Wheel Detail Quote Popup
$('.open-detail-quote-popup').magnificPopup({
  items: {
    src: '#detail-quote-popup',
    type: 'inline',
    closeBtnInside: false
  }
});

// Tire Detail Quote Popup
$('.open-tireDetail-quote-popup').magnificPopup({
  items: {
    src: '#tireDetail-quote-popup',
    type: 'inline'
  }
});

// Accessory Detail Quote Popup
$('.open-accessoryDetail-quote-popup').magnificPopup({
  items: {
    src: '#accessoryDetail-quote-popup',
    type: 'inline'
  }
});

$('.cart-open').magnificPopup({
  items: {
    src: '#cart-popup',
    type: 'inline'
  }
});

$('a.video, #videos a, .popup-video').magnificPopup({
  disableOn: 700,
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false
});

$('.loading-popup-modal').magnificPopup({
  type: 'inline',
  preloader: false,
  modal: true
});

// Ask the experts Quote Popup
$('.open-experts-quote-popup').on('click', function () {
  var packageName = $(this)[0].parentElement.children[0].innerText;
  $.magnificPopup.open({
    items: {
      src: '#experts-quote-popup',
      type: 'inline'
    },
    callbacks: {
      beforeOpen: function () {
        $('#contact-form').find('.product-info').val(packageName);
      }
    }
  });
});

$('.open-experts-quote-popup').magnificPopup({
  items: {
    src: '#experts-quote-popup',
    type: 'inline'
  },

  callbacks: {
    open: function () {
      // Will fire when this exact popup is opened
      // this - is Magnific Popup object
      console.log('magnific this: ', this);
    }
  }
});

// Time Estimate Quote Popup
$('.open-estimate-quote-popup').on('click', function () {
  var packageName = $(this)[0].parentElement.children[0].innerText;
  $.magnificPopup.open({
    items: {
      src: '#estimate-quote-popup',
      type: 'inline'
    },
    callbacks: {
      beforeOpen: function () {
        $('#contact-form').find('.product-info').val(packageName);
      }
    }
  });
});

$('.open-estimate-quote-popup').magnificPopup({
  items: {
    src: '#estimate-quote-popup',
    type: 'inline'
  },

  callbacks: {
    open: function () {
      // Will fire when this exact popup is opened
      // this - is Magnific Popup object
      console.log('magnific this: ', this);
    }
  }
});

// Back In Stock Quote Popup
$('.open-back-in-stock-popup').on('click', function () {
  var packageName = $(this)[0].parentElement.children[0].innerText;
  $.magnificPopup.open({
    items: {
      src: '#back-instock-quote-popup',
      type: 'inline'
    },
    callbacks: {
      beforeOpen: function () {
        $('#contact-form').find('.product-info').val(packageName);
      }
    }
  });
});

$('.open-back-in-stock-popup').magnificPopup({
  items: {
    src: '#back-in-stock-popup',
    type: 'inline'
  },

  callbacks: {
    open: function () {
      // Will fire when this exact popup is opened
      // this - is Magnific Popup object
      console.log('magnific this: ', this);
    }
  }
});

// price match popup
$('.open-price-popup').on('click', function () {
  var packageName = $(this)[0].parentElement.children[0].innerText;
  $.magnificPopup.open({
    items: {
      src: '#price-match-popup',
      type: 'inline'
    },
    callbacks: {
      beforeOpen: function () {
        $('#contact-form').find('.product-info').val(packageName);
      }
    }
  });
});

$('.open-price-match-popup').magnificPopup({
  items: {
    src: '#price-match-popup',
    type: 'inline'
  },
/*
  callbacks: {
    open: function () {
      // Will fire when this exact popup is opened
      // this - is Magnific Popup object
      console.log('magnific this: ', this);
    }
  }*/
});

// Newsletter Popup
$('.open-newsletter-popup').magnificPopup({
  items: {
    src: '#newsletter-popup',
    type: 'inline'
  }
});

// Add related items Cart Popup - Lugs and accessories
$('.open-related-popup').magnificPopup({
  items: {
    src: '#related-popup',
    type: 'inline',
    closeBtnInside: false
  },
  callbacks: {
    beforeOpen: function() {
      clearRelated();
      //console.log('clear related');
    },
    afterClose: function() {
      //console.log('trigger cart');
    }
  }
});
