// details
// slider
$("#gallery-main-image").magnificPopup({
  delegate: "a",
  type: "image",
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$("#gallery-main-image").slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: "#gallery-thumbs",
  fade: true,
  lazyLoad: "ondemand",
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots:true
      }
    }
  ]
});
$("#gallery-thumbs").slick({
  arrows: true,
  asNavFor: "#gallery-main-image",
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 3,
  responsive: [{
    breakpoint: 600,
    settings: {
      dots: true,
      slidesToScroll: 3,
      slidesToShow: 3
    }
  }]
});

// mobile - fade toggle
$('.vehicle-details-title').click(function(){
  detailsToggle($(this));
});

function detailsToggle(el){
  el.next().fadeToggle(function(){
      var $filterSelector = el;
      var $downArrow = $filterSelector.find('.fa-angle-up');
      var $upArrow = $filterSelector.find('.fa-angle-down');
    if($downArrow.length) {
      $downArrow.addClass('fa-angle-down').removeClass('fa-angle-up');
    } else {
      $upArrow.addClass('fa-angle-up').removeClass('fa-angle-down');
    }
  });
}





$(function () {
  // Header Filters
  $('#gallery-filter select').change(function changeBrand() {
    $('#gallery-search form').submit();
  });

})

///////// slide out filter ////////////
if($('.slide-filter-gallery').length) {
  // make
  $('#gallery-make-mobile').change(function(){
    $('#gallery-year-mobile').val('');
    let selected = $(this).children('option:selected').val();
    let modelField = $('#gallery-model-mobile');
    $.get('/ajax/gallerySlideFilter.cfm?filter=model&make=' +selected, function (data) {
      modelField.html(data).prop('disabled', false);
    });
  });

  $('#gallery-model-mobile').change(function(){
    let selected = $(this).children('option:selected').val();
    let makeVal = $('#gallery-make-mobile').children('option:selected').val();
    let yearField = $('#gallery-year-mobile');
    $.get('/ajax/gallerySlideFilter.cfm?filter=year&make='+makeVal+'&model='+selected, function (data) {
      yearField.html(data).prop('disabled', false);
    });
  });

  // fields display
  $('[data-toggle="offCanvasLeft"]').click(function(){
    let temp = $(this).attr('data-filter-display');
    let canvasTitle = $('.slide-over-title');
    let canvasTitleColor = canvasTitle.parent();
    $('#slide-filter select').show();
    if(temp == 'vehicle') {
      $('#gallery-brands-mobile').hide();
      $('#gallery-wsize-mobile').hide();
      canvasTitle.html('search vehicle gallery');
      canvasTitleColor.addClass('orange');
    } else if (temp == 'filter') {
      $('#gallery-make-mobile').hide();
      $('#gallery-model-mobile').hide();
      $('#gallery-year-mobile').hide();
      canvasTitle.html('filter &amp; sort');
      canvasTitleColor.removeClass('orange');
    }
  })
}

if($('#gallery').length) {
  var viewport_width = document.documentElement.clientWidth;
  if(viewport_width <= 640) {

    $(window).scroll(function(){
      let fromTop = $(document).scrollTop();
      var cta = document.getElementsByClassName('top')[0];
      var pageHeader = document.getElementsByClassName('content-header')[0];
      var header = document.getElementsByClassName('main')[0];
      if(fromTop > 100) {
        document.getElementsByClassName('main')[0].after(cta);
        document.getElementsByClassName('top')[0].after(pageHeader);
        header.classList.add('cta-added');
      } else {
        document.getElementsByClassName('gallery-list')[0].before(cta)
        document.getElementsByClassName('page-content')[0].before(pageHeader)
        header.classList.remove('cta-added');
      }
    });
  }
}
///////// END : slide out filter ////////////


/// gallery submit
if($('#contact.gallery-submit, #contact.warranty-submit').length) {
  /////// Vehicle Information //////
  $('#vYear').change(function(){
    var makeField = $('#vMake');
    makeField.prop('disabled', false);
  });
  
  $('#vMake').change(function(){
    var selected = $(this).children("option:selected").val();
    var modelField = $('#vModel');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitModels.cfm?model=' +selected, function getStates(data) {
      modelField.html(data).prop('disabled', false);
    });
  });
  
  /////// Wheel Information ///////
  // wheel model
  $('#wModel').change(function(){
    var selected = $(this).children("option:selected").val();
    var nextField = $('#wFinish');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitFinishes.cfm?wheel=' +selected, function(data) {
      nextField.html(data).prop('disabled', false);
    });
    
    $('#wSizeFront').prop('disabled', true);
    $('#wSizeRear').prop('disabled', true);
    
  });
  // wheel finish
  $('#wFinish').change(function(){
    console.log('wheel finish change');
    var wModel = $('#wModel').val();
    var selected = $(this).children("option:selected").val();
    var nextField = $('#wSizeFront');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitSizeFront.cfm?wheel=' +wModel+ '&finish=' +selected, function(data) {
      nextField.html(data).prop('disabled', false);
    });
    
    $('#wSizeRear').prop('disabled', true);
    
  });
  $('#wSizeFront').change(function(){
    console.log('wheel finish change');
    var wModel = $('#wModel').val();
    var wFinish = $('#wFinish').val();
    var nextField = $('#wSizeRear');
    
    // implemeent model selections
    $.get('/ajax/getGallerySubmitSizeRear.cfm?wheel=' +wModel+ '&finish=' +wFinish, function(data) {
      nextField.html(data).prop('disabled', false);
    });
    
  });
  
  
  /*
  var endWI = 4;
  $('.wheel-info-field').change(function(){
    var thisClasses = $(this).attr('class');
    var thisClass = thisClasses.replace('wheel-info-field ','');
    var thisNumber = thisClass.match(/\d+/)[0];
    thisNumber = parseInt(thisNumber,10);
    var nextNumber = thisNumber + 1;
    var nextField = 'wi-' + nextNumber;
    
    // find a match and set it free
    if ( thisNumber != endWI ) {
      $('.wheel-info-field').each(function(){
        var className = $(this).attr('class');
        if ( className.includes(nextField) ) {
          $(this).prop('disabled',false);
        }
      });
    };
  });
  */
  
}


// Event Gallery Details 
$('.event-gallery-detail .event-gallery')
    .magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false,
      },
    });





// results display
if($('#gallery.results').length) {
  $('.wheel').each(function(){
    var currentWheel = $(this);
    var imageID = currentWheel.data('imageid');
    var wheelID = currentWheel.data('wheelid');
    var partNumber = currentWheel.data('wheelpart');
    var url = '/gallery/json/wheelinfo.cfm?imageID=' +imageID+ '&wheelID=' +wheelID+ '&wheelPart=' +partNumber;
    
    var jqxhr = $.getJSON( url, function(data) {
      //console.log( "success" );
      //console.log(data);
      
      // replace name
      currentWheel.find('.name').html(data.NAME);
      // replace finish
      if(data.FINISH != '') {
        currentWheel.find('.finish').html(data.FINISH);
      }
      // replace size
      if(data.SIZE != '') {
        currentWheel.find('.size').html(data.SIZE);
      }
    })
      .done(function(data) {
        //console.log( "second success" );
      })
      .fail(function() {
        //console.log( "error" );
      })
      .always(function() {
        //console.log( "complete" );
      });
  });
}


// results filter
if($('#gallery.results').length) {
  $('#gallery-year').change(function changeMake() {
    //$('#gallery-make').val('');
    //$('#gallery-model').val('');
    $('#gallery-trim').val('');
    $('#gallery-wSize').val('');
    $('#gallery-tSize').val('');
    
    let $year = $(this).val();
    $('#yearCollection').val($year);
    
    
    /*
    $.get('/ajax/galleryFilterTop.cfm?year=' + $year,
      function galleryFiltering(data) {
        $('#gallery-search').html(data).prop('disabled', false);
    });
    */
    $('#gallery-filter-form').submit();
  });
  
  $('#gallery-make').change(function changeMake() {
    $('#gallery-model').val('');
    $('#gallery-trim').val('');
    $('#gallery-wSize').val('');
    $('#gallery-tSize').val('');
    
    let $make = $(this).val();
    $('#makeCollection').val($make);
    $('#yearCollection').val('');
    $('#modelCollection').val('');
    
    /*
    let $year = $('#gallery-year').val();
    let $make = $(this).val();
    
    $.get('/ajax/galleryFilterTop.cfm?year=' + $year + '&make=' + $make,
      function galleryFiltering(data) {
        $('#gallery-search').html(data).prop('disabled', false);
    });
    */
    $('#gallery-filter-form').submit();
  });

  $('#gallery-model').change(function changeModel() {
    $('#yearCollection').val(''); 
    $('#gallery-trim').val('');
    $('#gallery-wSize').val('');
    $('#gallery-tSize').val('');
    
    let $model = $(this).val();
    $('#modelCollection').val($model);
    
    /*
    let $year = $('#gallery-year').val();
    let $make = $('#gallery-make').val();
    let $model = $(this).val();
    
    $.get('/ajax/galleryFilterTop.cfm?year=' + $year + '&make=' + $make + '&model=' + $model,
      function galleryFiltering(data) {
        $('#gallery-search').html(data).prop('disabled', false);
    });
    */
    $('#gallery-filter-form').submit();
  });
  $('#gallery-trim').change(function changeModel() {
    $('#gallery-wSize').val('');
    $('#gallery-tSize').val('');
    
    let $trim = $(this).val();
    $('#trimCollection').val($trim);
    
    $('#gallery-filter-form').submit();
  });
}




if ($('#gallery.results').length) {
  // wheels header 
  
  // hide header models
  $('.hide-model').hide();
  // wheels header - show more models
  $('.wheels-see-more a').click(function(){
    $('.hide-model').show();
    $('.wheels-see-more').hide();
  });
  
  // product filter
  $(".product-filter input[type='checkbox'], .product-filter.vehicle select").change(function() {
    loadSpinner();
    //$('.filter-form').submit();
  });
  $(".product-filter.filter-active a").click(function() {
    loadSpinner();
  });
  
  
  // product filter - wheel size ///////////////
  // collect existing wheel size
  var $wheelSizes = $('#wheelSizeCollection');
  var $collWheelSizes = $wheelSizes.val();
  
  // if change update
  $('.item-wSize').change(function(){
    console.log('pew pew pew');
    // clear existing
    $collWheelSizes = '';
    
    // loop through new selection
    $('.item-wSize').each(function() {
      if(this.checked) {
        $collWheelSizes += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collWheelSizes = trimString($collWheelSizes);
    
    // update collection
    $wheelSizes.val($collWheelSizes);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  // remove from active
  $(".selectedwheelsize").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collWheelSizes = removeVal($collWheelSizes,val)    
    
    // replace values
    $wheelSizes.val($collWheelSizes);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  
  
  // product filter - brand ///////////////
  // collect existing brand
  var $brands = $('#brandCollection');
  var $collBrand = $brands.val();
  
  // if change update 
  $('.item-brand').change(function() {
    
    // clear existing
    $collBrand = '';
    
    // loop through new selection
    $('.item-brand').each(function() {
      if(this.checked) {
        $collBrand += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBrand = trimString($collBrand);
    
    // update collection
    $brands.val($collBrand);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  // remove from active
  $(".selectedbrand").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBrand = removeVal($collBrand,val)    
    
    // replace values
    $brands.val($collBrand);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  
  
  // product filter - tire size ///////////////
  // collect existing tire size
  var $tireSizes = $('#tireSizeCollection');
  var $collTireSizes = $tireSizes.val();
  
  // if change update
  $('.item-tSize').change(function(){
    
    // clear existing
    $collTireSizes = '';
    
    // loop through new selection
    $('.item-tSize').each(function() {
      if(this.checked) {
        $collTireSizes += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collTireSizes = trimString($collTireSizes);
    
    // update collection
    $tireSizes.val($collTireSizes);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  // remove from active
  $(".selectedtiresize").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collTireSizes = removeVal($collTireSizes,val)    
    
    // replace values
    $tireSizes.val($collTireSizes);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  
  // product filter - search ////////////
  var $search = $('#searchCollection');
  
  $('.filter-gallery-search button').click(function(){
    // replace values
    var $searchValue = $('#search').val();
    $search.val($searchValue);
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  // search by hiting enter
  $('.filter-gallery-search input').keypress(function(e){
    // replace values
    var $searchValue = $('#search').val();
    $search.val($searchValue);
    
    if (e.which == 13) {
      $(this).blur();
      // submit form
      $('#gallery-filter-form').submit();
    };
  });
  
  
  
  // clear selection 
  $('.filter-active-clear a').click(function(){
    // remove values
    $wheelSizes.val('');
    $brands.val('');
    $tireSizes.val('');
    $('#searchCollection').val('');
    
    // submit form
    $('#gallery-filter-form').submit();
  });
  
  
  
  
  
  
  
} // end filter

// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}

// loading spinner
function loadSpinner() {
  $.magnificPopup.open({
    items: {
      src: '<div class="white-popup processing">' +
        '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
        '</div>'
    },
    type: 'inline',
    modal: true
  });
}