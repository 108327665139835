/* global $, Foundation, MotionUI, List */
/* ==========================================================================
   Table of Contents
   ========================================================================== */

/*
    1.   General
    ---  Product Pages  ---
    2.1  Brand Pages
    2.2  Detail Pages
    ---  Gallery Pages  ---
    3.1  Gallery Landing Page
    3.3  Gallery Detail Page
    ---  Other Pages  ---
    4.1  Home Page
    4.2  Contact Page
    4.3  Article Page
*/
/* ==========================================================================
1. General
========================================================================== */
$(document).foundation();

var $img = $('.parallax .img');
var $heading = $('.parallax h1');
var scroller;
var wh = $(window).height();

$('#fitmentSubmit').on('click', function submitFitment() {
  var size = $('#size');
  if (size.val() && size.val() !== 'Select Size') {
    size.change();
  }
});

// Mobile Accordion
if ($('.accordion').length && Foundation.MediaQuery.atLeast('medium')) {
  $('.accordion').each(function openAccordion() {
    $(this).foundation(
      'down',
      $(this)
      .find('.accordion-content')
      .first(),
      true
    );
  });
}

// Scroll to section
$('.scroll-to').on('click', function scrollToElem(event) {
  var headerHeight = $('header').height();
  var target = $($(this).attr('href').replace('/wheels/','')); // replaces() for menu scrolling
  var wheelSection = 0;
  if ( $(this).attr('href').includes('wheels') ) {
    var wheelSection = 200;
  } 
  var scrollTarget = target.offset().top - headerHeight;
  if (target.length) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: scrollTarget,
      },
      1000
    );
  }
});

// wheels landing 
$(window).on('scroll',function(){
  $('.products-series').css('padding-top','1rem');
});

$('.rfqSubmit, .check-captcha').on('click', function (e) {
  const form = e.target.closest('form');
  const captchaId = form.dataset.captchaId;

  if (grecaptcha.getResponse(captchaId) === '') {
    var response = form.querySelector('#check-captcha');
    response.innerHTML = 'Captcha box not selected';
    return false;
  } else {
    const contactSubmit = form.querySelector('input[type="submit"]');
    console.log('contact submit', contactSubmit);
    form.requestSubmit(contactSubmit);
    return true;
  }
});

//Show/Hide navigation search box form
var searchBoxFlag = {
  active: false,
};

$(document).click(function (e) {
  closeSearchBox(e);
});

function closeSearchBox(e) {
  var target = $('.nav-search');

  if (!searchBoxFlag.active) {
    return;
  }

  if (!target.is(e.target) && target.has(e.target).length === 0) {
    $('.js-search-box').slideUp();
    $('.js-overlay-nav').addClass('hidden');
    searchBoxFlag.active = false;
  }
}

// search menu link slide toggle
$('.js-search-link').on('click', function (e) {
  e.preventDefault();
  $('.js-search-box').slideToggle();
  $('.js-overlay-nav').toggleClass('hidden');
  searchBoxFlag.active = !searchBoxFlag.active;
});

$('.js-search-btn').magnificPopup({
  items: {
    src: '<div class="white text-center processing">' +
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
      '</div>',
    type: 'inline',
    modal: true,
  },
  callbacks: {
    open: function () {
      $('#search-form').submit();
    },
  },
});


/// header - same height
let logoHeight = $('.top-bar-title').height();
let headerMenu = $('#responsive-menu');
headerMenu.height(logoHeight);


/// header - mobile /////////////
let stickyHeader = $('.sticky-container');
let headerLogo = $('.header-logo img.hide-for-large');
let headerMenuOpen = $('.open');
let menuButton = $('.menu-icon');

menuButton.click(function(){
  if(stickyHeader.hasClass('menu-open')) {
    // remove class
    stickyHeader.removeClass('menu-open');
    // return menu text
    $(this).html('<i class="fas fa-bars"></i>').removeClass('menu-open');
    // return header image
    //headerLogo.attr('src','/images/utilities/logo-black-header.png');
  } else {
    // add class
    stickyHeader.addClass('menu-open');  
    // update menu text
    $(this).html('back').addClass('menu-open');
    // update header image
    //headerLogo.attr('src','/images/utilities/logo-black-header.png');
  }
});

/// header - mobile - more menu //////////////
let topMobileMenu = $('.vertical.large-horizontal.menu.drilldown');
let bottomMobileMenu = $('.header-mobile-only');

topMobileMenu.after(bottomMobileMenu);

/// header loading cursor on click ////////
//if(window.outerWidth > 1023) {
  $('#responsive-menu a').click(function(){
    let link = $(this).attr('href');
    console.log(link);
    if(link != 'javascript:void(0);' && typeof link != 'undefined') {
      $('.loading-popup-modal').trigger('click');
    }
  });
//}

// header search
$( ".toggle-search").click(function() {
  $( ".header-nav-search" ).toggle( "slow" );
});

$('#header-search button').click(function(){
  $('#header-search').submit();
});

// mega menu
if(window.outerWidth > 1023) {
  $('.open-megamenu').next().hide();
  //console.log('this is next: ',$('.open-megamenu').next());
  $('.open-megamenu').mouseover(function(){
    let menu = $(this).attr('data-megamenu');
    console.log(menu);
    $('.header-menu').each(function(){
      let temp = $(this).attr('data-megamenu-menu');
      if(temp !== menu) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
    $('.megamenu-container').show();
  });
  $('.megamenu-container').mouseleave(function(){
    $(this).hide();
  });
  $('#responsive-menu a:not(.open-megamenu,.megamenu-menu-item)').hover(function(){
    $('.megamenu-container').hide();
  });
  $('.close-megamenu').click(function(){
    $('.megamenu-container').hide();
  })
}

// footer
$('.footer-menu-collection .accordion-title').click(function(){
  console.log('i click up');
  let button = $(this).find('i');
  console.log(button.hasClass('fa-chevron-down'));
  if(button.hasClass('fa-chevron-up') == true){
    button.addClass('fa-chevron-down').removeClass('fa-chevron-up');
  } else if (button.hasClass('fa-chevron-down') == true) {
    button.addClass('fa-chevron-up').removeClass('fa-chevron-down');
  }
});

//header 
$('.header-search #header-product-type').change(function(){
  let type = $(this).val();
  console.log(type);
  let action = $('.header-search form').attr('action');
  let placeholder = $('.header-search input[name="search"]').attr('placeholder');
  console.log(action);
  console.log(placeholder);
  if(type == 'Tires') {
    $('.header-search form').attr('action','/tires/');
    $('.header-search input[name="search"]').attr('placeholder','search tire name or part number');
    console.log(action);
  } else {
    $('.header-search form').attr('action','/wheels/');
    $('.header-search input[name="search"]').attr('placeholder','search wheel name or part number');
  }
});

/* ==========================================================================
     2.1 Brand Pages
     ========================================================================== */

// Function for Wheel Filter to change page to 1 when a brand is selected
// on a page greater than 1
function replaceUrlParam(url, key, value) {
  if (value === null) value = '';
  var pattern = new RegExp('\\b(' + key + '=).*?(&|$)');
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + value + '$2');
  }
  return url + (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
}

// Wheel filters
$('.js-finish-filter, .js-brand-filter, #tireFilters').on('change', 'select', function (event) {
  event.preventDefault();
  // Get type of filter and current query
  var $this = $(this);
  var type = $this.attr('name');
  var query = window.location.search;
  var urlSection = '';
  // Maintain BrandID and BrandName
  var brandID; // Use this method if still using web.config to rename url string to friendly urls
  var title = $this.find('option:selected').text();
  title = title.replace(/\s+/g, '-').toLowerCase();

  var brandTitle;
  var wheelMatch = window.location.href.match('wheels/' + /\d+/) > -1;

  // If this filter has already been applied, replace
  if (query.indexOf(type + '=') > -1) {
    var regex = new RegExp('(' + type + '=)([^&]+)?', 'ig');
    query = query.replace(regex, '$1' + $this.val());
    // If there are already other params
  } else if (query.indexOf('?') > -1) {
    if (type == 'brand' && wheelMatch) {
      // set brandID variable
      brandID = $this.val();
      brandTitle = title;
    } else {
      query += '&' + type + '=' + $this.val();
    }
    // If this is the only param
  } else {
    if (type == 'brand' && wheelMatch) {
      // set brandID variable
      brandID = $this.val();
      brandTitle = title;
    } else {
      query += '?' + type + '=' + $this.val();
    }
  }
  // Set the page to 1
  if (window.location.href.match('page')) {
    query = replaceUrlParam(query, 'page', 1);
  }
  if ($this.parent().attr('id') == 'tireFilters') {
    if (window.location.href.match('tires/' + /\d+/) > -1) {}
  } else if (window.location.href.match('wheels/' + /\d+/) > -1) {
    var urlSplit = document.location.href.split('/');
    if (typeof brandID !== 'undefined') {
      // replaces the brandID in url string
      urlSection = brandID;
    } else {
      urlSection = urlSplit[4];
      brandTitle = urlSplit[5];
    }
    if (urlSection > 0) {
      window.location.href = '/wheels/' + urlSection + '/' + brandTitle + '/' + query;
    } else {
      window.location.href = '/wheels/' + query;
    }
  } else {
    window.location.href = window.location.pathname + query;
  }
});

// PARALLAX EFFECT   //codepen.io/sallar/pen/lobfp
// requestAnimationFrame Shim
window.requestAnimFrame = (function animFramPoly() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function animTimout(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

// Scroller
function Scroller() {
  this.latestKnownScrollY = 0;
  this.ticking = false;
}

Scroller.prototype = {
  // Initialize
  init: function init() {
    window.addEventListener('scroll', this.onScroll.bind(this), false);
  },

  // Capture Scroll
  onScroll: function onScroll() {
    this.latestKnownScrollY = window.scrollY;
    this.requestTick();
  },

  // Request a Tick
  requestTick: function requestTick() {
    if (!this.ticking) {
      window.requestAnimFrame(this.update.bind(this));
    }
    this.ticking = true;
  },

  // Update
  update: function update() {
    var currentScrollY = this.latestKnownScrollY;

    // Do The Dirty Work Here
    var imgScroll = currentScrollY / 2;
    var headScroll = currentScrollY / 3;

    this.ticking = false;

    $img.css({
      transform: 'translateY(' + imgScroll + 'px)',
      '-moz-transform': 'translateY(' + imgScroll + 'px)',
      '-webkit-transform': 'translateY(' + imgScroll + 'px)',
    });

    $heading.css({
      transform: 'translateY(' + headScroll + 'px)',
      '-moz-transform': 'translateY(' + headScroll + 'px)',
      '-webkit-transform': 'translateY(' + headScroll + 'px)',
    });
  },
};

// Attach!
if ($('.parallax .img').length || $('.parallax h1').length) {
  scroller = new Scroller();
  scroller.init();
}

/* ==========================================================================
     WHEEL FILTERS
     ========================================================================== */
if ($('.wheel-filter').length) {
  // Disable 'width' when no diameter is available
/*  $('#wheel-diam').on('change', function () {
    if ($(this).val() !== '') {
      $('#wheel-width').prop('disabled', false);
    } else {
      $('#wheel-width').prop('disabled', true);
    }
  });*/

  $('.wheel-filter select').on('change', function () {
    $('.js-show-search').removeClass('hidden');
  });

  $('.wheel-filter').on('submit', function (event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: '<div class="white text-center processing">' +
          '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
          '</div>',
      },
      closeBtnInside: false,
    });

    var filterURL = $('.wheel-filter :input')
      .filter(function (index, element) {
        return $(element).val() !== '';
      })
      .serialize();

    window.location.href = '/wheels/?' + filterURL;
  });
}

/* ==========================================================================
     2.2 Detail Pages
     ========================================================================== */
// Slider and popup for main image
function initializeMainImage() {
  $('.main-image')
    .magnificPopup({
      delegate: 'a',
      type: 'image',
      mainClass: 'popup-bkg tester',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false,
      },
    }).slick({
      arrows: false,
      asNavFor: '.alt-images',
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

  $('.alt-images').slick({
    arrows: false,
    asNavFor: '.main-image',
    dots: false,
    focusOnSelect: true,
    infinite: false,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    vertical:true,
    verticalSwiping:true,
    responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 4,
          
          
        }
      },
    ]
  });
}

$(window).scroll(function(){
  //console.log('scrolling now')
  //console.log($(this));
  //console.log($(this).offset.left);
  //console.log($(document).scrollTop());
})
$('.alt-images').scroll(function(){
  //console.log('scrolling inside container');
});
initializeMainImage();

// Gallery View Popup
$('.galleryView-open').on('click', function () {
  for (var dataName in $(this).data()) {
    if ($(this).data()[dataName] === '') {
      continue;
    }

    if (dataName === 'wheelimage') {
      $('#galleryView-popup img').attr('src', $(this).data()[dataName]);
    }

    var htmlString = '';
    htmlString = dataName;
    htmlString += ': ';
    htmlString += $(this).data()[dataName];
    htmlString = htmlString.replace(/car: /, '').replace(/size/, ' size');
    $('.galleryView-info')
      .find('.popup-' + dataName)
      .html(htmlString);
  }
  $.magnificPopup.open({
    items: {
      src: '#galleryView-popup',
      type: 'inline',
    },
  });
});

// Show/Hide excess paragraph lines
function getTextHeight(selector) {
  var height = parseFloat($(selector).height());
  var lineHeight = parseFloat($(selector).css('line-height'));
  return Math.round(height / lineHeight);
}

// Show/Hide excess information
if ($('.info--limit').length) {
  if (getTextHeight('.info--limit') > 5) {
    $('.info--limit').addClass('info--height');
    $('.info-show').removeClass('hidden');
  }
}

if ($('.wheel-galleryList').length) {
  if ($('.wheel-galleryList__item').length > 6) {
    $('.wg-Show').removeClass('hidden');
  }
}

function toggleButtons(classSelect, cssSelect, toggleShowBtn, toggleHideBtn) {
  $(classSelect).toggleClass(cssSelect);
  $(toggleShowBtn).toggleClass('hidden');
  $(toggleHideBtn).toggleClass('hidden');
}

$('.info-show, .info-hide').on('click', function toggleParagraph() {
  toggleButtons('.info--limit', 'info--height', '.info-show', '.info-hide');
});
$('.wg-Show, .wg-Hide').on('click', function toggleGallery() {
  toggleButtons(
    '.wheel-galleryList__item',
    'wheel-galleryList__item--display',
    '.wg-Show',
    '.wg-Hide'
  );
});

function toggleBoltPatStringSize(type, event) {
  var bpList = $('.js-bpStringLength').data('list');
  var bpArray = bpList.split(',');

  if (event === undefined) {
    return;
  }

  if (type !== 'more' && bpArray.length > 5) {
    bpList = bpArray.slice(0, 5);
  }
  if (event.type === 'click') {
    $('.js-bpStringLength')
      .siblings('.js-show-less')
      .toggleClass('hidden');
    $('.js-bpStringLength')
      .siblings('.js-show-more')
      .toggleClass('hidden');
  } else {
    $('.js-bpStringLength')
      .siblings('.js-show-more')
      .removeClass('hidden');
  }

  $('.js-bpStringLength').text('Custom Drilled ' + bpList);
}

if ($('.js-bpStringLength').length) {
  toggleBoltPatStringSize('default', '');
  $('.js-show-more').on('click', function () {
    toggleBoltPatStringSize('more', event);
  });
  $('.js-show-less').on('click', function () {
    toggleBoltPatStringSize('less', event);
  });
}


/* ==========================================================================
     4.1  Home Page
     ========================================================================== */
/* MOTION UI - RANDOM TRANSITIONS /////////*/
if (Foundation.MediaQuery.atLeast('medium')) {
  $(window).scroll(function scrollPage() {
    $('.animate').each(function animateElem() {
      var $this = $(this);
      var transitions = [
        'slide-in-left',
        'slide-in-up',
        'slide-in-right',
        'fade-in',
        'hinge-in-from-right',
        'hinge-in-from-bottom',
        'hinge-in-from-left',
      ];
      var randomNum = Math.floor(Math.random() * (transitions.length - 1));
      if ($this.offset().top - $(window).scrollTop() - wh <= 0) {
        MotionUI.animateIn($this, transitions[randomNum]);
        $this.removeClass('animate');
      }
    });
  });
}

/* ==========================================================================
     js back button
     ========================================================================== */
var closePopup = function closePopup() {
  $.magnificPopup.close();
};

function onPageLoad() {
  closePopup();
}