// filter toggle - filters
$('.filter-title, .vehicle-active, .product-filter .vehicle-title').click(function(){
  filterToggle($(this));
});

function filterToggle(el){
  el.next().fadeToggle(function(){
      var $filterSelector = el;
      var $downArrow = $filterSelector.find('.fa-minus');
      var $upArrow = $filterSelector.find('.fa-plus');
    if($downArrow.length) {
      $downArrow.addClass('fa-plus').removeClass('fa-minus');
    } else {
      $upArrow.addClass('fa-minus').removeClass('fa-plus');
    }
  });
}
function filterToggleFitment(){
  $('.product-filters').fadeToggle(function(){
    var $filterSelector = $(this).prev().find('.filter-active-title');
    var $downArrow = $filterSelector.find('.fa-minus');
    var $upArrow = $filterSelector.find('.fa-plus');
    
    if($downArrow.length) {
      $downArrow.addClass('fa-plus').removeClass('fa-minus');
    } else {
      $upArrow.addClass('fa-minus').removeClass('fa-plus');
    }
  });
}
/*
$('.filter-title, .vehicle-active, .product-filter .vehicle-title').click(function(){
  filterToggle($(this));
});
*/

$('.filter-active-title').click(function(){
  filterToggleFitment();
});

// for mobile
if(window.innerWidth < 1024) {
  //if($('#wheels').length || $('#tires').length) {
    /*
    $('.filter-title, .vehicle-active, .product-filter .vehicle-title').each(function(){
      filterToggle($(this));
    });
    filterToggleFitment();*/
  //}
}

// product listing filter
/*
$('.vehicle-title h4').click(function(){
  $(this).parent().next().fadeToggle(function(){
    console.log(' we doing it');
    var $filterSelector = $(this).prev().find('h4');
    var $downArrow = $filterSelector.find('.fa-caret-down');
    var $upArrow = $filterSelector.find('.fa-caret-up');
    
    if($downArrow.length) {
      $downArrow.addClass('fa-caret-up').removeClass('fa-caret-down');
    } else {
      $upArrow.addClass('fa-caret-down').removeClass('fa-caret-up');
    }
  });
});
*/
// filter toggle - vehicles
/*$('.vehicle-active').click(function(){
  $(this).next().fadeToggle(function(){
    var $filterSelector = $(this).prev();
    console.log($filterSelector.find('.fa-caret-down'));
    if($filterSelector.find('.fa-caret-down').length) {
      $filterSelector.find('.fa-caret-down').addClass('fa-caret-up').removeClass('fa-caret-down');
    } else {
      $filterSelector.find('.fa-caret-up').addClass('fa-caret-down').removeClass('fa-caret-up');
    }
  });
});*/

// filter show more
$('.filter .filter-items').each(function () {

  var list = $(this).find('li');
  var button = $(this).parent().find('button');
  var numToShow = 5;
  var numInList = list.length;
  //console.log(numInList);
  list.hide();

  if (numInList > numToShow) {
    button.show();
  } else {
    button.hide();
  }
  list.slice(0, numToShow).css('display','flex');

  button.click(function () {
    console.log('lets try something new');
    var showing = list.filter(':visible').length;
    if (showing < numInList) {
      list.slice(showing - 1, numInList).fadeIn();
      list.css('display', 'flex');
      button.text('- Show Less');
    } else if (showing == numInList) {
      list.slice(numToShow).fadeOut();
      button.text('+ Show More');
    }
  });
});
// end - show more

// filter show more
/*
$('.filter-items').each(function () {

  var list = $(this).find('li');
  var button = $(this).parent().find('button');
  var numToShow = 5;
  var numInList = list.length;
  //console.log(numInList);
  list.hide();

  if (numInList > numToShow) {
    button.show();
  } else {
    button.hide();
  }
  list.slice(0, numToShow).show();

  button.click(function () {
    console.log('lets try something new');
    var showing = list.filter(':visible').length;
    if (showing < numInList) {
      list.slice(showing - 1, numInList).fadeIn();
      list.css('display', 'list-item');
      button.text('Less');
    } else if (showing == numInList) {
      list.slice(numToShow).fadeOut();
      button.text('More');
    }
  });
});
// end - show more
*/


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}

// loading spinner
function loadSpinner() {
  $.magnificPopup.open({
    items: {
      src: '<div class="white-popup processing">' +
        '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
        '<div class="processing-title">Searching...</div>' +
        '</div>'
    },
    type: 'inline',
    modal: true
  });
}