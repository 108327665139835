if ($('#wheels,#wheel-brands').length) {
  
  // mobile
  var viewport_width = document.documentElement.clientWidth;
  if(viewport_width <= 640) {

    $(window).scroll(function(){
      let fromTop = $(document).scrollTop();
      var cta = document.getElementsByClassName('top')[0];
      var pageHeader = document.getElementsByClassName('content-header')[0];
      var header = document.getElementsByClassName('main')[0];
      if(fromTop > 100) {
        document.getElementsByClassName('main')[0].after(cta);
        document.getElementsByClassName('top')[0].after(pageHeader);
        header.classList.add('cta-added');
      } else {
        document.getElementsByClassName('products-list')[0].before(cta)
        document.getElementsByClassName('page-content')[0].before(pageHeader)
        header.classList.remove('cta-added');
      }
    });
  }

  ////// slide filter 
  var viewport_width = document.documentElement.clientWidth;
  if(viewport_width <= 640) {
    // Declare a fragment:
    var fragment = document.getElementsByClassName('product-filters')[0];
    var fragment2 = document.getElementsByClassName('filter-active')[0];

    // Append fragment to desired element:
    document.getElementsByClassName('slide-over-filter')[0].appendChild(fragment);
    document.getElementsByClassName('slide-over-filter')[0].insertBefore(fragment2,fragment);
  }

  // wheels header 
  
  // hide header models
  $('.hide-model').hide();
  
  // wheels header - show more models
  $('.wheels-see-more a').click(function(){
    let temp = $(this).parent();
    let tempParent = temp.parent();
    
    console.log(temp);
    tempParent.find('.hide-model').show();
    //$('.hide-model').show();
    temp.hide();
  });
  
  // product filter
  $(".product-filter input[type='checkbox']").change(function() {
    loadSpinner();
    //$('.filter-form').submit();
  });
  $(".product-filter.filter-active a").click(function() {
    loadSpinner();
  });
  
  
  // product filter - diameter ///////////////
  // collect existing diameter
  var $diameters = $('#diameterCollection');
  var $collDiameter = $diameters.val();
  
  // if change update 
  $('.item-diameter').change(function() {
    
    // clear existing
    $collDiameter = '';
    
    // loop through new selection
    $('.item-diameter').each(function() {
      if(this.checked) {
        $collDiameter += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collDiameter = trimString($collDiameter);
    
    // update collection
    $diameters.val($collDiameter);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selecteddiameter").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collDiameter = removeVal($collDiameter,val)    
    
    // replace values
    $diameters.val($collDiameter);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - width ///////////////
  // collect existing width
  var $widths = $('#widthCollection');
  var $collWidth = $widths.val();
  
  // if change update 
  $('.item-width').change(function() {
    
    // clear existing
    $collWidth = '';
    
    // loop through new selection
    $('.item-width').each(function() {
      if(this.checked) {
        $collWidth += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collWidth = trimString($collWidth);
    
    // update collection
    $widths.val($collWidth);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedwidth").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collWidth = removeVal($collWidth,val)    
    
    // replace values
    $widths.val($collWidth);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - offset ///////////////
  // collect existing offset
  var $offset = $('#offsetCollection');
  var $collOffset = $offset.val();
  
  // if change update 
  $('.item-offset').change(function() {
    
    // clear existing
    $collOffset = '';
    
    // loop through new selection
    $('.item-offset').each(function() {
      if(this.checked) {
        $collOffset += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collOffset = trimString($collOffset);
    
    // update collection
    $offset.val($collOffset);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedoffset").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collOffset = removeVal($collOffset,val)    
    
    // replace values
    $offset.val($collOffset);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - bolt pattern ///////////////
  // collect existing bolt pattern
  var $bolts = $('#boltpatCollection');
  var $collBolt = $bolts.val();
  
  // if change update 
  $('.item-bolt').change(function() {
    
    // clear existing
    $collBolt = '';
    
    // loop through new selection
    $('.item-bolt').each(function() {
      if(this.checked) {
        $collBolt += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBolt = trimString($collBolt);
    
    // update collection
    $bolts.val($collBolt);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedbolt").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBolt = removeVal($collBolt,val)    
    
    // replace values
    $bolts.val($collBolt);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - brand ///////////////
  // collect existing brand
  var $brands = $('#brandCollection');
  var $collBrand = $brands.val();
  // collect existing style
  var $styles = $('#styleCollection');
  var $collStyle = $styles.val();
  
  // if change update 
  $('.item-brand').change(function() {
    
    // clear existing
    $collBrand = '';
    $collStyle = '';
    
    // loop through new selection
    $('.item-brand').each(function() {
      if(this.checked) {
        $collBrand += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBrand = trimString($collBrand);
    
    // update collection
    $brands.val($collBrand);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedbrand").click(function() {
    console.log('cllick');
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBrand = removeVal($collBrand,val)    
    $collStyle = '';
    
    // replace values
    $brands.val($collBrand);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - styles ///////////////
  // if change update 
  $('.item-style').change(function() {
    
    // clear existing
    $collStyle = '';
    
    // loop through new selection
    $('.item-style').each(function() {
      if(this.checked) {
        $collStyle += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collStyle = trimString($collStyle);
    
    // update collection
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedstyle").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collStyle = removeVal($collStyle,val)    
    
    // replace values
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  // header styles
  $('.wheel-style').click(function(){
    var selecledStyle = $(this).attr('data-val');

    // loop through new selection
    $('.item-style').each(function() {
      var checkboxValue = $(this).val();

      if(selecledStyle == checkboxValue) {
        $(this).prop('checked',true);
        $(this).trigger('change');
      }
    })
  });
  
  
  // product filter - construction ///////////////
  // collect existing construction
  var $constructions = $('#constructionCollection');
  var $collConstruction = $constructions.val();
  
  // if change update 
  $('.item-construction').change(function() {
    
    // clear existing
    $collConstruction = '';
    
    // loop through new selection
    $('.item-construction').each(function() {
      if(this.checked) {
        $collConstruction += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collConstruction = trimString($collConstruction);
    
    // update collection
    $constructions.val($collConstruction);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedconstruction").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collConstruction = removeVal($collConstruction,val)    
    
    // replace values
    $constructions.val($collConstruction);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - finish ///////////////
  // collect existing finish
  var $finishes = $('#finishCollection');
  var $collFinish = $finishes.val();
  
  // if change update 
  $('.item-finish').change(function() {
    
    // clear existing
    $collFinish = '';
    
    // loop through new selection
    $('.item-finish').each(function() {
      if(this.checked) {
        $collFinish += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collFinish = trimString($collFinish);
    
    // update collection
    $finishes.val($collFinish);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedfinish").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collFinish = removeVal($collFinish,val)    
    console.log($collFinish);
    
    // replace values
    $finishes.val($collFinish);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - search ////////////
  var $search = $('#searchCollection');
  
  $('.filter-search-field button').click(function(){
    // replace values
    var $searchValue = $('#search').val();
    $search.val($searchValue);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // search by hiting enter
  $('.filter-search-field input').keypress(function(e){
    // replace values
    var $searchValue = $('#search').val();
    $search.val($searchValue);
    
    if (e.which == 13) {
      $(this).blur();
      // submit form
      $('.filter-form').submit();
    };
  });
  
  // remove from active
  $(".searchPhrase").click(function() {

    // remove selected value from collection
    $search.val('');
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - search wheel ////////////
  var $searchWheel = $('#searchWheelCollection');
  
  // search by hiting enter
  $('.wheels-name-search input').keypress(function(e){
    // replace values
    var $searchWheelValue = $('#searchwheel').val();
    $searchWheel.val($searchWheelValue);
    
    if (e.which == 13) {
      $(this).blur();
      // submit form
      $('.filter-form').submit();
    };
  });
  
  // remove from active
  $(".searchWheelPhrase").click(function() {

    // remove selected value from collection
    $searchWheel.val('');
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // clear selection 
  $('.filter-active-clear a').click(function(){
    // remove values
    $diameters.val('');
    $widths.val('');
    $offset.val('');
    $bolts.val('');
    $brands.val('');
    $styles.val('');
    $constructions.val('');
    $finishes.val('');
    $('#searchCollection').val('');
    $('#searchWheelCollection').val('');
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  
  
} // end wheels

//// wheel brands
if($('#wheel-brands').length){
  // gray out missing brands 
  let availableBrands = [];
  
  $('.products-list > .column').each(function(){
    let temp = $(this).attr('data-letter');
    if(!availableBrands.includes(temp)) {
      availableBrands.push(temp);
    }
  });
  availableBrands.sort();

  // loop through to see if button available;
  $('.brand-letter-search #wheel-filter-search ul li').each(function(){
    let temp = $(this).find('a')
    let tempValue = temp.attr('href');
    let isAvailable = false;
    for(let i = 0; i < availableBrands.length; i++) {
      if(tempValue == availableBrands[i]) {
        isAvailable = true;
      } else if (tempValue == '0-9' && !isNaN(availableBrands[i])) {
        isAvailable = true;
      }
    }
    if (isAvailable == false) {
      temp.addClass('disable-letter');
    }
  });

  // END : gray out missing brands
};


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}
