$('.js-home-slider').slick({
    autoplay: true,
    dots: true,
    arrows: false,
    fade:true,
    adaptiveHeight: true,
    autoplaySpeed: 4000
});

if ($('.hp').length) {
    if ($(window).width() < 640) {
        $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
        $('.js-footer-social').insertAfter('.support-list');
    }

    var moveHTML = debounce(function () {
        if ($(window).width() < 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
            $('.js-footer-social').insertAfter('.support-list');
        }
        if ($(window).width() > 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__header');
            $('.js-footer-social').insertAfter('.footer__about');
        }
    }, 500);

    window.addEventListener('resize', moveHTML);
}

// Vehicle Gallery ////////////////
$('.js-home-gallery-slider').slick({
    autoplay: true,
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    fade:true,
    autoplaySpeed: 5000,
});


// Featured Wheels ///////////////
$(window).load(function(){
    var $showNumber = 6;
    if($(window).width() <= 667) {
        $showNumber = 1;
    } else {
        $showNumber = 6;
      
      
        $('#featured-slider').slick({
            autoplay: true,
            dots: false, 
            arrows: true,
            slidesToShow: $showNumber,
            swipeToSlide: true,
            nextArrow: '<button class="featured-next featured-arrow" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>',
            prevArrow: '<button class="featured-prev featured-arrow" aria-label="Prev" type="button"><i class="fas fa-chevron-left"></i></button>',
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: false
                }
              }
            ]
        });
    }

});

// instagram scrolling ///////////////////
$(window).load(function(){
    var $showNumber = 6;
    if($(window).width() <= 667) {
        $showNumber = 1;
    } else {
        $showNumber = 6;
      
      
      $('#instagram').slick({
        autoplay: true,
        dots: false, 
        arrows: false,
        slidesToShow: $showNumber,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
    });
    }
});